import BigNumber from 'bignumber.js';
import { Button, CustomExcalmationMark, Modal } from 'components';
import { FundsModal } from 'components/FundsModal/FundsModal';
import useWithdrawFund from 'hooks/tokenomics/useWithdrawFund';
import { Link } from 'react-router-dom';

interface WithdrawFundProps {
  accountBalance?: BigNumber;
  setIsOpen: ((_: boolean) => void) | undefined;
  successModalSubmitTo?: string;
  renderContent?: React.ReactElement;
}

const WithdrawFund = ({ renderContent, ...props }: WithdrawFundProps) => {
  const {
    amount,
    amountError,
    error,
    fundRefAddress,
    handleSubmit,
    isWithdrawLoading,
    setAmount,
    LOWER_LIMIT,
    Summary,
    setWithdrawHash,
    withdrawHash,
    withdrawStatus,
  } = useWithdrawFund();

  return withdrawStatus === 'Error' ? (
    <Modal
      isOpen={true}
      setIsOpen={props.setIsOpen}
      isDarkTheme={true}
      classNames={{ base: '!max-w-[27rem]' }}
    >
      <div className="flex flex-col items-center text-center text-white gap-2">
        <CustomExcalmationMark />
        <div className="mx-4 text-2xl mt-4">Your Withdraw Failed!</div>
        <div className="mx-4 text-sm text-[#b2b3b8]">
          Click
          <Link
            target="_blank"
            to={`https://explorer.${import.meta.env.MODE}.analog.one/#/block/${withdrawHash}`}
            className="pl-1 bg-[linear-gradient(283deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)] bg-clip-text !text-transparent"
          >
            here
          </Link>
          <span className="ml-1 text-base text-[#b2b3b8]">for more details</span>
        </div>
        <div className="flex my-2 gap-2">
          <Button
            variant="plain"
            className="border border-solid bg-white text-black border-white px-5 rounded-full"
            onClick={() => {
              props.setIsOpen && props.setIsOpen(false);
              setWithdrawHash('');
            }}
          >
            See my profile
          </Button>
        </div>
      </div>
    </Modal>
  ) : (
    <FundsModal
      {...props}
      amount={amount}
      setAmount={setAmount}
      amountError={amountError}
      error={error}
      Summary={Summary}
      fundRefAddress={fundRefAddress}
      onSubmit={handleSubmit}
      isLoading={isWithdrawLoading}
      showBalance
      isOpen
      texts={{
        header: ' Withdraw $TANLOG',
        headerSub:
          'To withdraw $TANLOG from your Analog Watch account. Note that withdrawing $TANLOG tokens from the Watch account locks it from deploying and querying views on the Analog Watch.',
        submit: 'Withdraw',
        inputLabel: 'Withdraw amount',
        inputPlaceHolder: 'Amount',
      }}
      successTexts={{
        header: `Great news! Your funds have been successfully withdrawed. 🎉`,
        submitToButton: 'See My Profile',
      }}
      renderContent={renderContent}
      LOWER_LIMIT={LOWER_LIMIT}
    />
  );
};

export default WithdrawFund;
