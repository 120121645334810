import { classes } from 'utils';

import ProfileDetailsStatistics from './components/ProfileDetailsStatistics';
import ProfileOverview from './components/ProfileOverview';
import WalletOverview from './components/WalletOverview';
import { ProfileDetailsProps } from './types';

export function ProfileDetails({
  value,
  className,
  classNames,
  showIcon = true,
  isDarkTheme,
  showAccountOptions = false,
  handleClick,
  accountBalance,
  tokenDecimals,
  totalRewardEarned,
  totalSpent,
  sessionKey,
  isLoading,
  onDownload,
  countData,
  RefreshUserBalance,
  isUserBalanceLoading,
  unsyncedBalance,
}: ProfileDetailsProps) {
  return (
    <div className={classes('card relative p-6', className, classNames?.base)}>
      <div
        className={classes(
          'flex w-full  items-center',
          isDarkTheme && 'gap-[18px] lg:flex-row md:flex-col flex-col'
        )}
      >
        <ProfileOverview
          isDarkTheme={isDarkTheme}
          value={value}
          classNames={classNames}
          isLoading={isLoading}
          onDownload={onDownload}
          sessionKey={sessionKey}
        />
        {showAccountOptions && (
          <WalletOverview
            RefreshUserBalance={RefreshUserBalance}
            accountBalance={accountBalance}
            handleClick={handleClick}
            isUserBalanceLoading={isUserBalanceLoading}
            tokenDecimals={tokenDecimals}
            unsyncedBalance={unsyncedBalance}
          />
        )}
      </div>
      <ProfileDetailsStatistics
        isDarkTheme={isDarkTheme}
        countData={countData}
        showIcon={showIcon}
        totalSpent={totalSpent}
        totalRewardEarned={totalRewardEarned}
      />
    </div>
  );
}
