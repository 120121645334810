/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';

import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import BigNumber from 'bignumber.js';
import BN from 'bn.js';
import { Skeleton } from 'components';
import { useApi, useMyProfile } from 'contexts';
import { formatBalance, getWithdrawTransactionGasCost, withdrawAmount } from 'utils';

const useWithdrawFund = () => {
  const LOWER_LIMIT = 0.2;
  const [amount, setAmount] = useState<number | string>('');
  const [fundRefAddress, setFundRefAddress] = useState<string | null | undefined>(null);
  const [error, setError] = useState<string>('');
  const [amountError, setAmountError] = useState('');
  const [gasFee, setGasFee] = useState<number>(0);
  const [withdrawStatus, setWithdrawStatus] = useState('');
  const [withdrawHash, setWithdrawHash] = useState('');
  const [isGasFeeLoading, setIsGasFeeLoading] = useState<boolean>(false);
  const {
    account,
    chainProps: { tokenDecimals },
    api,
  } = useApi();
  const [isWithdrawLoading, setWithdrawLoading] = useState(false);
  const { refetchAccountBalance, accountBalance } = useMyProfile();

  // Ref for debounce mechanism
  const debounceRef = useRef<number>();

  // Formatted gas fee calculation
  const formattedGasFee = useMemo(() => {
    return Number(
      formatBalance(new BigNumber(gasFee), {
        symbol: '',
        decimalPlaces: 5,
        tokenDecimals,
        roundingMode: 0,
      })
    );
  }, [gasFee, tokenDecimals]);

  // Calculate creditable amount after deducting gas fee
  const creditableAmount = useMemo(() => {
    return (Number(amount) - formattedGasFee).toFixed(5);
  }, [amount, formattedGasFee]);

  useEffect(() => {
    const isExceeds = new BigNumber(amount || 0)
      .multipliedBy(10 ** tokenDecimals)
      .isGreaterThan(accountBalance || 0);
    if (Number(accountBalance) <= 0) {
      setAmountError('Insufficient balance');
    } else if (isExceeds) {
      setAmountError('Amount exceeds your account balance');
    } else if (Number(amount) && Number(amount)! < LOWER_LIMIT) {
      setAmountError('Amount is below the limit');
    } else {
      setAmountError('');
    }
    if (error) {
      setError('');
    }
    if (fundRefAddress) {
      setFundRefAddress(null);
    }
  }, [amount, tokenDecimals]);

  const getGasFee = async () => {
    if (Number(amount) && !amountError) {
      try {
        const value = new BigNumber(amount).multipliedBy(10 ** tokenDecimals).toNumber();
        const BN_HUNDRED = new BN('100');
        const partialFee = await getWithdrawTransactionGasCost(
          api,
          account as InjectedAccountWithMeta,
          value
        );
        const adjFee = partialFee.muln(110).div(BN_HUNDRED);
        setGasFee(adjFee?.toNumber());
        setIsGasFeeLoading(false);
        return adjFee;
      } catch (e) {
        setIsGasFeeLoading(false);
        setAmountError('Something went wrong. Try again');
      }
    }
    return 0;
  };

  useEffect(() => {
    setIsGasFeeLoading(true);
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(getGasFee, 800) as unknown as number;
  }, [amount, tokenDecimals, amountError]);

  const handleSubmit = () => {
    setWithdrawLoading(true);
    withdrawAmount(
      api,
      account as InjectedAccountWithMeta,
      Number(creditableAmount),
      tokenDecimals,
      (status, hash) => {
        if (status === 'Error') {
          setWithdrawStatus(status);
          setWithdrawLoading(false);
          return;
        }
        if (status === 'isInBlock') {
          setWithdrawStatus(status);
          setWithdrawLoading(true);
          setWithdrawHash(hash);
        }
        if (status === 'isFinalized') {
          setWithdrawStatus((prev) => (prev === 'Error' ? 'Error' : status));
          setFundRefAddress(hash);
          setWithdrawLoading(false);
          refetchAccountBalance();
        }
      },
      setWithdrawLoading,
      setError
    );
  };

  const Summary = useMemo(
    () => (
      <>
        <div className="border-[#1f1f1f] border-[1px]"></div>
        <div className="text-xs font-normal leading-[16px] uppercase text-[rgba(255,255,255,0.40)]">
          Summary
        </div>
        <div className="flex flex-col gap-3 text-sm font-normal leading-[20px] text-[#B2B3B8]">
          <div className="flex justify-between">
            <span>Fees</span>
            <span
              data-cy={`${formattedGasFee}`}
              className="flex text-[#fff] text-sm font-normal leading-[20px] gap-2"
            >
              <Skeleton.Loader className="w-8" isDarkTheme isLoading={isGasFeeLoading}>
                {formattedGasFee}
              </Skeleton.Loader>
              <span className="text-[#808080]">ANL</span>
            </span>
          </div>
          <div className="flex justify-between">
            <span>Total Creditable Amount</span>
            <span
              data-cy={`${creditableAmount}`}
              className="flex gap-2 text-[#fff] text-sm font-normal leading-[20px]"
            >
              <Skeleton.Loader className="w-8" isDarkTheme isLoading={isGasFeeLoading}>
                {Number(creditableAmount) > 0 ? creditableAmount : 0}
              </Skeleton.Loader>
              <span className="text-[#808080]"> ANL</span>
            </span>
          </div>
        </div>
      </>
    ),
    [creditableAmount, formattedGasFee, isGasFeeLoading]
  );

  return {
    amount,
    setAmount,
    amountError,
    fundRefAddress,
    error,
    isWithdrawLoading,
    handleSubmit,
    LOWER_LIMIT,
    Summary,
    withdrawStatus,
    withdrawHash,
    setWithdrawHash,
  };
};

export default useWithdrawFund;
